import React, { useState, useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useParams } from 'react-router-dom';
import client from '../config/contentful';
import { Heading, Main, Container} from '../components/GlobalComponents';
import Logo from '../components/Logo';
import { Link } from "react-router-dom";

const BasicPage = () => {
    const [page, setPage] = useState(null);
    const { slug } = useParams(); // Get the slug from the URL

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'simplePage',
          'fields.slug': slug, // Filter based on the slug field
          limit: 1, // Assuming there is only one matching page
        });

        if (response.items.length > 0) {
          setPage(response.items[0]);
        }
      } catch (error) {
        console.error('Error fetching Contentful data:', error);
      }
    };

    fetchPage();
  }, [slug]); // Trigger the effect whenever the slug changes

  if (!page) {
    return <div>Loading...</div>; // Render a loading state while fetching the page
  }

  return (
    <Main>
      <Container style={{ padding: 40 }}>
        <Link to='/'>
          <Logo />
        </Link>
        <Heading fontSize={{ base: '32px', md: '56px', lg: '56px' }} style={{ color: '#fff', marginTop:'24px', textAlign: 'center' }}>{page.fields.heading}</Heading>
        <p style={{ color: '#979797', marginTop: '32px' }}>{documentToReactComponents(page.fields.body)}</p>
      </Container>
    </Main>
  );
};

export default BasicPage;
