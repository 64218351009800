import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import supabase from '../config/supabaseConfig';
import styled from 'styled-components';
import clientManage from '../config/contentfulManagement';
import client from '../config/contentful';
import { Heading, Main, Container, StyledButton } from '../components/GlobalComponents';
import Logo from '../components/Logo';

const ActionContainer = styled.div`
  margin-Top: 64px;
  background: #FFFFFF;
  width: 100%;
  border-radius: 40px;
  padding: 32px;
`

const ButtonStyle = styled.button`
  :disabled {
    opacity: 0.4;
    cursor: not-allowed !important;
  }
`

const PartnerFeature = styled.div`
  margin-top: 16px;
  border-radius: 16px;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    transform: scale(1.02);
  }
`

function ActionPage() {
  const { uuid } = useParams();
  const [entryId, setEntryId] = useState();
  const [voted, setVoted] = useState(false);
  const [partners, setPartners] = useState('');
  const [partnersData, setPartnersData] = useState('');

  const [actionData, setActionData] = useState({
    title: '',
    description: '',
    date: ''
  });

  useEffect(() => {
    const fetchActionData = async () => {
      try {
        const { data, error } = await supabase
          .from('actions')
          .select('description, date, prompt_id, hasRated')
          .eq('uuid', uuid)
          .single();

        if (error) {
          console.log(error);
        } else {
          setActionData(data);
          setEntryId(data.prompt_id);
          setVoted(data.hasRated);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchActionData();
  }, [uuid]);

  useEffect(() => {
    if (actionData.description) {
      checkKeywords(); // Call checkKeywords() only when description is available
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData.description]);

  // if actionData.description contains keyword, then return the matched item
  function checkKeywords() {
    const keywords = ['Spring', 'Kajabi', 'Pietra'];
    const words = actionData.description.split(' ');

    const matchedItem = keywords.find(keyword => words.some(word => word.includes(keyword)));

    setPartners(matchedItem);
  }

  useEffect(() => {
    if (partners) { // Check if partners is not empty
      const fetchPartners = async () => {
        try {
          const response = await client.getEntries({
            content_type: 'partners',
            'fields.partner': partners // Filter based on the 'partner' field
          });
          const partnerEntries = response.items;
          
          if (partnerEntries.length > 0) {
            // Process the partner entries as needed
            setPartnersData(partnerEntries[0].fields);
          }
    
        } catch (error) {
          console.error('Error fetching partners:', error);
        }
      };
    
      fetchPartners();
    }
  }, [partners]);

  if (!actionData) {
    return <div>Loading...</div>;
  }

  const handleLike = async () => {
    setVoted(true);
    try {
      const space = await clientManage.getSpace(process.env.REACT_APP_CONTENTFUL_SPACE_ID);
      const environment = await space.getEnvironment('master');
      const entry = await environment.getEntry(entryId);
      const likes = entry.fields.likes && entry.fields.likes['en-US'] ? entry.fields.likes['en-US'] : 0;
      entry.fields.likes = { 'en-US': likes + 1 };
      await entry.update();
      
      // update the Supabase 'actions' table
      const { error } = await supabase
        .from('actions')
        .update({ hasRated: true })
        .eq('uuid', uuid);
      
      if (error) {
        console.error('Error updating hasVoted:', error);
      } else {
        console.log('Field value and hasVoted updated successfully.');
      }
    } catch (error) {
      console.error('Error updating field value:', error);
      setVoted(false);
    }
  };
  
  const handleDislike = async () => {
    setVoted(true);
    try {
      const space = await clientManage.getSpace(process.env.REACT_APP_CONTENTFUL_SPACE_ID);
      const environment = await space.getEnvironment('master');
      const entry = await environment.getEntry(entryId);
      const dislikes = entry.fields.dislikes && entry.fields.dislikes['en-US'] ? entry.fields.dislikes['en-US'] : 0;
      entry.fields.dislikes = { 'en-US': dislikes + 1 };
      await entry.update();
      
      // update the Supabase 'actions' table
      const { error } = await supabase
        .from('actions')
        .update({ hasRated: true })
        .eq('uuid', uuid);
      
      if (error) {
        console.error('Error updating hasVoted:', error);
      } else {
        console.log('Field value and hasVoted updated successfully.');
      }
    } catch (error) {
      console.error('Error updating field value:', error);
      setVoted(false);
    }
  };

  // Parse the description field to an object
  
  let descriptionObj = {};
try {
  if (actionData.description) {
    descriptionObj = JSON.parse(actionData.description);
  }
} catch (error) {
  console.error('Error parsing description:', error);
}
  
  return (
    <Main>
      <Container style={{ padding: 40 }}>
        <Link to="/">
          <Logo />
        </Link>
        <ActionContainer>
          <Heading fontSize={{ base: '32px', md: '56px', lg: '56px' }}>{descriptionObj.title}
          </Heading>
          <p style={{ textAlign: 'left' }}>Date: {actionData.date}</p>
          <p style={{ marginTop: 24 }}>{descriptionObj.description}</p>
          {partnersData && (
            <a href={partnersData.baseLink} target='_blank' rel='noreferrer'>
              <StyledButton >{partnersData.ctaText}</StyledButton>
            </a>
          )}
          <div
            style={{
              marginTop: 16,
              display: 'flex',
              gap: 8, 
            }}
          >
            <ButtonStyle disabled={voted} onClick={handleLike}><img width='20' height='20' src="/assets/icons/thumbs-up-outline.svg" alt='like'/></ButtonStyle>
            <ButtonStyle disabled={voted} onClick={handleDislike}><img width='20' height='20' src="/assets/icons/thumbs-down-outline.svg" alt='dislike'/></ButtonStyle>
          </div>
          {partnersData && (
            <>
              <Heading style={{ marginTop: 24 }} fontSize={{ base: '24px', md: '24px', lg: '24px' }}>Recomended partner: {partnersData.partner}</Heading>
              <PartnerFeature>
                <a href={partnersData.baseLink} target='_blank' rel='noreferrer'>
                  <img src={partnersData.asset.fields.file.url} alt='partner'/>
                </a>
              </PartnerFeature>
            </>
          )}
        </ActionContainer>
      </Container>
    </Main>
  );
}

export default ActionPage;
