import React, { useState } from "react";
import { Input } from "@chakra-ui/react";
import styled from "styled-components";

const Label = styled.label`
  position: absolute;
  font-weight: normal;
  pointer-events: none;
  left: 16px;
  top: 16px;
  transition: 0.2s ease all;
  
  &.label-float {
    top: 5px;
    font-size: 12px !important;
    background-color: var(--neutral05) !important;
    padding: 0 4px;
    margin-left: -4px;
    z-index: 1;
  }

  &.placeholder-shown {
    color: ${props => (props.placeholder || "")};
  }
`;

const FloatInput = styled.div`
  position: relative;
  input {
    padding: 1rem 0.75rem 0rem 0.75rem;
    height: calc(3.5rem + 2px);
    border-radius: 16px !important;
    background-color: var(--neutral05) !important;
    border: none !important;
  }
`;

const FloatingInput = ({ label, value, name, onChange }) => {
  const [focus, setFocus] = useState(false);

  const labelClass = focus || value ? "label label-float" : "label placeholder-hidden";

  return (
    <div className="App">
      <FloatInput>
        <Input
          name={name}
          value={value}
          onChange={onChange}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
        />
        <Label className={labelClass}>{label}</Label>
      </FloatInput>
    </div>
  );
};

export default FloatingInput;
