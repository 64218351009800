import styled from 'styled-components'
import { Text, Button } from '@chakra-ui/react';

export const Heading = styled(Text)`
  font-family: 'Archivo Narrow', sans-serif;
  font-weight: 400;
  line-height: 107%;
  letter-spacing: -0.03em;
`

export const Main = styled.div`
  background: #181C1F;
  min-height: 100vh;
  width: 100%;
  display: inline-block;
`

export const Container = styled.div`
  max-width: 540px;
  margin: 64px auto 0px auto;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const Form = styled.form`
  background: #FFFFFF;
  width: 100%;
  border-radius: 40px;
  padding: 32px;
`

export const StyledButton = styled(Button)`
  background-color: var(--primary) !important;
  color: #fff;
  border-radius: 200px !important;
  width: 100%;
  font-size: 16px !important;
  height: 56px !important;
  font-family: 'JetBrains Mono', monospace;
  margin-top: 24px;
  &:hover {
    background-color: var(--primary-dark) !important;
    color: #fff;
  }
`