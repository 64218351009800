import React, { useState, useEffect } from 'react';
import supabase from '../config/supabaseConfig';
import client from '../config/contentful';
// import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import styled from 'styled-components';
import FloatingInput from '../components/FloatingInput.js';
import Logo from '../components/Logo.js';
import { useNavigate } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Heading, Main, Container, Form, StyledButton,  } from '../components/GlobalComponents';

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
  margin-bottom: 32px;
`

const Banner = styled.div`
  background: var(--primary);
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  padding: 40px 32px;
  margin: 24px 40px;
  border-radius: 20px;
  font-family: 'JetBrains Mono', monospace;
`

/* const StyledPhoneInput = styled(PhoneInput)`
  width: 100%;
  height: 56px;
  gap: 8px;
  .react-international-phone-country-selector button {
    height: 100% !important;
    border-radius: 16px !important;
    background-color: var(--neutral05) !important;
    padding: 0 16px !important;
    border: none !important;
  }
  input {
    background-color: var(--neutral05) !important;
    border-radius: 16px !important;
    width: 100%;
    height: 100% !important;
    border: none !important;
  }
` */

const Fieldgroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Privacy = styled.span`
  font-size: 12px;
  text-transform: uppercase;
  line-height: 154.52%;
  display: inline-block;
  text-align: center;
  margin-top: 16px;

`

function LandingPage() {
  const [email, setEmail] = useState('');
  const [socialLink, setSocialLink] = useState('');
  const [homepageContent, setHomepageContent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHomepageContent = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'homepage',
          limit: 1, // Assuming only one entry for the homepage
        });

        if (response.items.length > 0) {
          setHomepageContent(response.items[0]);
        }
      } catch (error) {
        console.error('Error fetching homepage content:', error);
      }
    };

    fetchHomepageContent();
  }, []);

  if (!homepageContent) {
    return <div>Loading...</div>;
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      // Register the user with the email address
      const { data, error } = await supabase.auth.signUp({ email, user_metadata: { socialLink: socialLink }, password: 'password' });

      if (error) {
        console.log('Error registering user:', error);
      } else {
        console.log('User registered:', data.user.id);

        // Send verification email
        //await supabase.auth.sendVerificationEmail(email);

        // Store the social link and other user details in the database
        const { error: dbError } = await supabase
          .from('userDetails')
          .insert([{ userId: data.user.id, socialLink: socialLink }])
          .single();

        if (dbError) {
          console.log('Error storing user details:', dbError);
        } else {
          // console.log('User details stored:', data);


          // creating first action
          const { error: dbError } = await supabase
            .from('actions')
            .insert([{ userId: data.user.id, email, type: 'introductory', description: socialLink }])
            .single();

          if (dbError) {
            console.log('Error adding first action:', dbError);
          } else {

          // Clear the form fields
          setEmail('');
          setSocialLink('');

          // Handle the success
          console.log('Form submission added successfully!');

          // Redirect to a verification page or display a success message
          navigate('/thanks');

          // ...
        }

      }

      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <Main>
      <Banner id="marketing-banner" tabindex="-1">
          <div>
              <p>{homepageContent.fields.banner}</p>
          </div>
      </Banner>
      <Container>
        <Intro>
          <Logo />
          <Heading fontSize={{ base: '32px', md: '56px', lg: '56px' }} style={{ color: '#fff', marginTop:'24px' }}>{homepageContent.fields.heading}</Heading>
          <p style={{ color: '#979797' }}>{homepageContent.fields.subHeading}</p>
        </Intro>
        <Form onSubmit={handleFormSubmit}>
          <Fieldgroup>
            <FloatingInput
              type="text"
              label="Platform link"
              value={socialLink}
              onChange={(e) => setSocialLink(e.target.value)}
            />
            <FloatingInput
              type="email"
              label="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            </Fieldgroup>
          <StyledButton type="submit">{homepageContent.fields.cta}</StyledButton>
          <Privacy>{documentToReactComponents(homepageContent.fields.privacyAndTerms)}</Privacy>
        </Form>
      </Container>
    </Main>
  );
}

export default LandingPage;
