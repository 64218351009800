import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SupabaseContext from './config/SupabaseContext';
import { AuthProvider } from './context/AuthContext';
import './App.css';
import supabase from './config/supabaseConfig'; // Import the supabase object
import client from './config/contentful';
import { Helmet } from 'react-helmet';

// pages
import ActionPage from './pages/ActionPage';
import LandingPage from './pages/LandingPage';
import BasicPage from './pages/BasicPage';
import Login from './pages/Login';

function App() {
  const [appearance, setAppearance] = useState(null);

  useEffect(() => {
    const fetchAppearance = async () => {
      try {
        const response = await client.getEntry('5sbUQhcUOcpQrqRUsiEvwy');
  
        if (response) {
          setAppearance(response.fields);
        }
      } catch (error) {
        console.error('Error fetching appearance content:', error);
      }
    };
  
    fetchAppearance();
  }, []);

  return (
    <SupabaseContext.Provider value={supabase}>
      <AuthProvider>
        <Helmet>
          {/* Place any static meta tags here */}
          <title>{appearance?.metaTitle}</title>
          <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
          <link rel="icon" href={'https:' + appearance?.favicon.fields.file.url} />
          <meta name="description" content={appearance?.metaDescription} />
          <meta name="keywords" content={appearance?.metaKeywords} />
          <meta property="og:title" content={appearance?.metaTitle} />
          <meta property="og:description" content={appearance?.metaDescription} />
          {/* Add more static meta tags as needed */}
        </Helmet>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/actions/:uuid" element={<ActionPage />} />
            <Route path="/:slug" element={<BasicPage />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Router>
      </AuthProvider>
    </SupabaseContext.Provider>
  );
}

export default App;
