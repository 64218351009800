import { useAuth } from '../context/AuthContext';
import supabase from '../config/supabaseConfig';

function Login() {
  const user = useAuth();

  const handleSignIn = async () => {
    const { user, session, error } = await supabase.auth.signInWithOAuth({
      provider: 'google'
    });

    // Here's how you might use the `user`, `session`, and `error` variables:

    // `user` contains the user's data, if they're logged in
    if (user) {
      console.log('User is logged in:', user);
    }

    // `session` contains the user's current session data
    if (session) {
      console.log('User session data:', session);
    }

    // `error` contains any error that occurred during sign-in
    if (error) {
      console.error('An error occurred during sign-in:', error);
    }
  };

  if (user) {
    return <div>You are already logged in! {user.email}</div>;
  } else {
    return <button onClick={handleSignIn}>Sign in with Google</button>;
  }
}

export default Login;
