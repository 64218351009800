
import React, { useState, useEffect } from 'react';
import client from '../config/contentful';

function Logo() {
    const [appearance, setAppearance] = useState(null);

    useEffect(() => {
        const fetchAppearance = async () => {
          try {
            const response = await client.getEntry('5sbUQhcUOcpQrqRUsiEvwy');
      
            if (response) {
              setAppearance(response.fields);
            }
          } catch (error) {
            console.error('Error fetching appearance content:', error);
          }
        };
      
        fetchAppearance();
      }, []);

      const svgCode = appearance?.logoSvg;
      const svgUrl = `data:image/svg+xml,${encodeURIComponent(svgCode)}`;


    return (
        <img src={svgUrl} width='169' height='70' alt='Ding!'/>
    )
}

export default Logo;
